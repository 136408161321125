body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s;
}

.light-theme a, .light-theme a:active, .light-theme a:visited, .light-theme a:hover {
  color: rgba(0,0,0,0.95);
}

.dark-theme a, .dark-theme a:active, .dark-theme a:visited, .dark-theme a:hover {
  color: rgba(255, 255, 255, 0.95);
}
